const data = [
  {
    "id": "Projet1",
    "title": "Projet OpenClassRooms 1",
    "cover" : "htmlcss.jpg",
    "image" : "booki.png",
    "minidesc" : "Transformer une maquette en site web avec HTML & CSS. \n Utilisation de HTML et CSS",
    "description": "Le premier projet de la formation OpenClassRooms consiste à reproduire un site internet avec HTML et CSS uniquement tout en respectant les normes W3C, le cahier des charges et l'ergonomie responsive du site. \n Le but de cet exercice est de se familiariser avec la bonne méthode de construction d'un site internet. ",
    "lien": "Booki",
    "temps": "2 semaines",
    "skills": "HTML/CSS/Code Responsive/Respect du cahier des charges/Présentation professionnelle du site sous forme de jeu de rôle/Respect W3C",
    "logo": "html5",
  },
  {
    "id": "Projet2",
    "title": "Projet OpenClassRooms 2",
    "cover" : "ohmyfood2.png",
    "image" : "ohmyfood.png",
    "minidesc" : "Développement d'un site mobile first en se basant sur le cahier des charges.\n Utilisation de HTML, CSS et Sass",
    "description": "Le second projet de la formation OpenClassRooms consiste à créer un site avec HTML, CSS et Sass.\n Le site nécessitant des animations et un travail plus soutenu sur CSS, l'utilisation de Sass était de rigueur, c'est pourquoi j'ai décidé de l'utiliser afin de faciliter son développement.",
    "lien": "Ohmyfood",
    "temps": "3 semaines",
    "skills": "HTML/CSS/Sass/Code Responsive/Respect du cahier des charges/Création d'animations CSS",
    "logo": "sass",
  },
  {
    "id": "Projet3",
    "title": "Projet OpenClassRooms 3",
    "cover" : "lapanthere.png",
    "image" : "opti.jpg",
    "minidesc" : "Optimiser un site web existant. \n Utilisation d'outils SEO et de performances.",
    "description": "Le troisième projet de la formation OpenClassRooms consiste à optimiser un site web déjà existant grâce à des outils SEO et faire un rapport détaillé des modifications et des améliorations apportées au site lors d'un entretien sous forme de jeu de rôle avec un interlocuteur jouant le rôle du client. \n Le site a été diagnostiqué grâce à des outils comme Google Lighthouse, GTMetrix et WAVE.",
    "lien": "Lapanthere",
    "temps": "2 semaines",
    "skills": "HTML/CSS/JavaScript/Optimisation SEO/Optimisation des performances/Présentation professionnelle des améliorations sous forme de jeu de rôle/Respect W3C",
    "logo": "bug",
  },
  {
    "id": "Projet4",
    "title": "Projet OpenClassRooms 4",
    "cover" : "kanap.png",
    "image" : "kanap2.jpg",
    "minidesc" : "Construire un site e-commerce en JavaScript \n Utilisation de JavaScript.",
    "description": "Le quatrième projet de la formation OpenClassRooms consiste à créer une liaison en JavaScript entre le frontend et le backend afin de créer un site e-commerce fonctionnel à l'aide d'un fichier JSON fourni contenant tous les produits à afficher. \n Le projet est essentiellement basé sur JavaScript, en effet toute la partie front est fournie par OpenClassRooms",
    "lien": "Kanap",
    "temps": "4 semaines",
    "skills": "JavaScript/Serveur/LocalStorage/Présentation professionnelle du site sous forme de jeu de rôle",
    "logo": "server",
  },
  {
    "id": "Projet5",
    "title": "Projet OpenClassRooms 5",
    "cover" : "piiquante.png",
    "image" : "piiquante2.png",
    "minidesc" : "Construire une API sécurisée pour une application d'avis gastronomiques \n Utilisation de JavaScript.",
    "description": "Le cinquième projet de la formation OpenClassRooms consiste à créer une API pour un site communautaire de sauces piquantes.\n L'API doit pouvoir gérer la création de compte, la connexion à son compte, l'ajout, la modification et la suppression d'une sauce piquante. Toutes ces données sont ensuite enregistrées de manière sécurisée sur un serveur MongoDB créé au préalable. \n L'API nécessitant la création d'un serveur MongoDB afin de fonctionner, je ne peux fournir un exemple sans modifier son code.\n \n Cependant voici la vidéo de la soutenance afin de comprendre le fonctionnement du site et de l'API : https://youtu.be/itMjFFpdnzk",
    "lien": "",
    "temps": "5 semaines",
    "skills": "JavaScript/API/Serveur/MongoDB/Présentation professionnelle du site sous forme de jeu de rôle",
    "logo": "terminal",
  },
  {
    "id": "Projet6",
    "title": "Projet OpenClassRooms 6",
    "cover" : "Kasa.png",
    "image" : "kasa2.jpg",
    "minidesc" : "Créer une application web de location immobilière avec React\n Utilisation de React (JavaScript et TypeScript)",
    "description": "Le dernier projet de la formation OpenClassRooms consiste à créer de A à Z un site sous React avec les assets et le cahier des charges fournis par OpenClassRooms.\n Plusieurs fonctionnalités sont attendues, comme le développement de plusieurs composants (carrousel, dropdown, galleries de photos), d'un système de routing avec gestion d'erreurs, la récupération des données dans un fichier JSON.",
    "lien": "Kasa",
    "temps": "6 semaines",
    "skills": "React/JavaScript/Respect du cahier des charges/Présentation professionnelle du site sous forme de jeu de rôle",
    "logo": "react",
  },
]
export default data;